const home = {
	'home.usdt.title': 'Баланс USDT',
	'home.usdt.title2': 'Текущий доход',
	'home.usdt.max': 'Максимум',
	'home.usdt.balance': 'Ваш баланс:',
	'home.usdt.total': 'Общий объём стейкинга:',
	'home.total.balance': 'Общая ликвидность:',
	'home.btn.approve': 'Подтвердить',
	'home.btn.stake': 'Внести в стейкинг',
	'home.withdraw.title': 'Доступно к выводу:',
	'home.withdraw.2': 'Вывести',
	'home.withdraw.earnings': 'Личный доход',
	'home.withdraw.amount': 'Общий доход',
	'home.withdraw.btn1': 'Получить',
	'home.withdraw.btn2': 'Реинвестировать',
	'home.team.title': 'Общий вклад сети',
	'home.team.1': 'Адрес',
	'home.team.upper': 'Реферальный адрес',
	'home.team.address.placeholder': 'Введите адрес кошелька',
	'home.team.address.s': 'Адрес успешно привязан',
	'home.team.leavel': 'Уровень',
	'home.team.bind': 'Привязать адрес',
	'home.invite.title': 'Реферальная ссылка',
	'home.invite.copy': 'Скопировать',
	'home.invite.tip': 'Сначала активируйте аккаунт',
	'home.message.tip0': 'Средства зачислены!',
	'home.message.tip1': 'Авторизация выполнена!',
	'home.message.tip2': 'Стейкинг выполнен!',
	'home.message.tip3': 'Минимальная сумма депозита: {{msg}} USDT',
	'home.message.tip4': 'Доступный баланс: {{msg}} USDT',
	'home.message.tip5': 'Скопировано!',
	'home.message.tip6': 'Нельзя привязать свой адрес',
	'home.message.tip7': 'Адрес успешно привязан!',
	'home.message.tip8': 'Вывод выполнен!',
	'home.message.tip9': 'Максимальная сумма вывода: {{msg}} USDT',
	'home.check.order.title': 'Проверить транзакцию',
	'home.2.stake.btn.1': '',
	'home.2.stake.btn.1.1': '15 дней',
	'home.2.stake.btn.2': 'Гибкий стейкинг',
	'home.2.stake.btn.3': 'Годовая процентная ставка (APY): {{msg}}%',
	'home.orders.title': 'Мои депозиты',
	'home.orders.tips1': 'Сумма депозита',
	'home.orders.tips2': 'Ожидаемый доход',
	'home.orders.tips3': 'Дата внесения',
	'home.orders.tips4': 'Дата окончания',
	'home.orders.tips5': 'Получено',
	'home.orders.btn1': 'Получить',
	'home.orders.btn2': 'Уже получено',
	'home.orders.no.more': 'Больше записей нет',
	'home.orders.claim.success': 'Средства успешно зачислены',
	'home.2.withdraw.title': 'Время до следующего вывода:',
	'home.regular.title': 'Доход от фиксированного стейкинга',
	'home.regular.reward.success': 'Начисление успешно',
	'home.regular.withdraw.success': 'Вывод успешно выполнен',
	'home.regular.tips1': 'Доступно к выводу',
	'home.regular.tips2': 'Ожидаемый доход',
	'home.regular.btn1': 'Вывести',
	'home.regular.btn2': 'Получить доход',
	'home.team.list.title1': 'Личный вклад',
	'home.team.list.title2': 'Совокупный вклад сети',
	'home.team.list.title3': 'Активные приглашения',
	'home.state.order.tips.max': 'Максимальное количество активных заявок — 25',
	'home.message.tip10': 'Недостаточная сумма депозита',
}

export default home
